import { Cookies } from '@/core/graphQlCookieManager'
import { Cookie, useCookie } from 'next-cookie'
import React, { useContext, useState } from 'react'
import { CookieContext } from '@/utils/sessionService'
import { Sdk, Experiment } from '@/network/graphql.g'
import { requestThrow401 } from '@/utils/graphQl'
import DateUtils from '@/core/date'

enum ExperimentStatus {
  Active = 1,
  Inactive = 0
}

export enum ExperimentType {
  PricePercentage = 'price_percentage_ČOI',
  FakeTestUser = 'Fake_User_Test',
  TowerCheckout = 'New_checkout_v1',
  FrontendApiRecommended = 'frontend_api_recommended'
}

export enum PricePercentageVariant {
  Original = '0',
  VariantA = '1'
}

export enum FrontendApiRecommendedVariant {
  Original = '0',
  VariantA = '1'
}

enum TowerCheckoutVariant {
  Original = '0',
  VariantA = '1'
}

const ExperimentContext = React.createContext<ExperimentContextType>(null)
type ExperimentContextType = {
  getVariant: (experiment: ExperimentType, defaultVariant?: string) => string
  getMhubExperiments: () => MhubExperiments
  getRunningExperiments: () => Experiment[]
  getMhubDefaultExperiments: (context?: CookieContext) => MhubDefaultExperiments
  activeExperimentVariants: string
  runningUserExperiments: string
  useTowerCheckout: boolean
}

export const useTowerCheckout = (): boolean =>
  useContext(ExperimentContext).useTowerCheckout

export const useExperiment = (): ExperimentContextType =>
  useContext(ExperimentContext)

export const useVariant = (
  experiment: ExperimentType,
  defaultVariant?: string
): string =>
  useContext(ExperimentContext)?.getVariant(experiment, defaultVariant) ??
  defaultVariant

export type MhubExperiments = {
  gaExpString: string
  runnnigVariants: Record<string, ExperimentMhubItem>
}

type MhubDefaultExperiments = {
  event: string
  action: {
    impress: {
      experiment: ExperimentMhubItem[]
    }
  }
}

type ExperimentMhubItem = {
  id: string
  name: string
  variantId: number
  variant: string
  active?: boolean
}

type ExperimentProviderProps = {
  runningExperiments: Experiment[]
  runningUserExperiments: string
}

const ExperimentProvider: React.FC<ExperimentProviderProps> = ({
  runningExperiments: initialRunningExperiments,
  runningUserExperiments: initialRunningUserExperiments,
  children
}) => {
  const [runningExperiments] = useState<Experiment[]>(
    initialRunningExperiments ?? []
  )
  const [runningUserExperiments] = useState<string>(
    initialRunningUserExperiments ?? ''
  )

  const getVariant = (
    experiment: ExperimentType,
    defaultVariant = '0'
  ): string =>
    getVariantBase(
      experiment,
      runningExperiments,
      runningUserExperiments,
      defaultVariant
    )

  const getRunningExperiments = () => runningExperiments

  const getMhubExperiments = (): MhubExperiments => {
    return {
      gaExpString: parseRunningUserExperiments(runningUserExperiments)
        .map(({ id, variant }) => ({
          experiment: runningExperiments.find((item) => item.id === id),
          variant
        }))
        .filter((item) => item && isRunningExperimentActive(item?.experiment))
        .map(({ experiment: { id }, variant }) => `${id}.${variant}`)
        .join('!'),
      runnnigVariants: runningExperiments.reduce((acc, item) => {
        const variantId = +getVariant(item.name as ExperimentType)
        const active = isRunningExperimentActive(item)

        if (active) {
          return (
            (acc[item.id] = {
              id: item.id,
              name: item.name,
              variantId: variantId,
              variant: item.variants.find(
                (variant) => variant.variantIndex === variantId
              )?.name,
              active
            }),
            acc
          )
        }
        return acc
      }, {} as Record<string, ExperimentMhubItem>)
    }
  }

  //Experiments which are triggered on specific place
  const disableExperiment = [ExperimentType.FakeTestUser]

  //Filter default active experiments
  const activeExperimentVariants = parseRunningUserExperiments(
    runningUserExperiments
  )
    .map(({ id, variant }) => ({
      experiment: runningExperiments.find((item) => item.id === id),
      variant
    }))
    .filter(
      (item) =>
        item &&
        isRunningExperimentActive(item.experiment) &&
        !disableExperiment.includes(item.experiment?.name as ExperimentType)
    )
    .map(({ experiment: { id }, variant }) => `${id}.${variant}`)
    .join('!')

  const cookie = useCookie()
  //Set cookie with default active experiments if doesnt exists
  if (!cookie.get(Cookies.activeExperimentsUserAffectionCookieName)) {
    cookie.set(
      Cookies.activeExperimentsUserAffectionCookieName,
      activeExperimentVariants,
      {
        path: '/',
        expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      }
    )
  }

  const useTowerCheckout =
    getVariant(ExperimentType.TowerCheckout, TowerCheckoutVariant.Original) ===
    TowerCheckoutVariant.VariantA

  const getMhubDefaultExperiments = (
    context: CookieContext
  ): MhubDefaultExperiments => {
    const cookie = useCookie(context)
    const impressDefaultExperiments = 'action.impress.experiment'
    return {
      event: impressDefaultExperiments,
      action: {
        impress: {
          experiment: runningExperiments
            .filter(
              (item) => !disableExperiment.includes(item.name as ExperimentType)
            )
            .reduce((acc, item) => {
              const active = isRunningExperimentActive(item)
              if (active) {
                const variantId = +getVariant(item.name as ExperimentType)
                acc.push({
                  id: item?.id,
                  name: item?.name,
                  variantId: variantId,
                  variant: item?.variants.find(
                    (variant) => variant.variantIndex === variantId
                  )?.name
                })
                cookie.set(
                  impressDefaultExperiments.replace(/\./g, '_'),
                  JSON.stringify(acc.map((item) => item.name)),
                  cookieOptions
                )
              }
              return acc
            }, [] as Array<ExperimentMhubItem>)
        }
      }
    }
  }

  return (
    <ExperimentContext.Provider
      value={{
        getVariant,
        getMhubExperiments,
        getRunningExperiments,
        getMhubDefaultExperiments,
        activeExperimentVariants,
        runningUserExperiments,
        useTowerCheckout
      }}
    >
      {children}
    </ExperimentContext.Provider>
  )
}

export default ExperimentProvider

export const parseRunningUserExperiments = (
  runningUserExperiments: string
): { id: string; variant: string }[] =>
  runningUserExperiments
    .split('!')
    .map((item) => item.split('.'))
    .map((item) => ({ id: item[0], variant: item[1] }))

const generateVariant = (experiment: Experiment): string => {
  const data = experiment.variants
    .map(({ ratio, variantIndex }) =>
      Array.from(Array(ratio).keys()).map(() => variantIndex)
    )
    .flat()
  return data[Math.floor(Math.random() * data.length)].toString()
}

const cookieOptions = {
  path: '/',
  expires: DateUtils.addYears(DateUtils.now(), 1)
}

const EnabledRunningUserExperimentsCookieName =
  'enabled_running_user_experiments'
const DisabledRunningUserExperimentsCookieName =
  'disabled_running_user_experiments'
const ExperimentsDateCookieName = 'experiments_date'

export const isExperimentCookieValid = (context: CookieContext): boolean => {
  const cookie = useCookie(context)
  // valid for 1h
  return (
    cookie.has(ExperimentsDateCookieName) &&
    +cookie.get<number>(ExperimentsDateCookieName) + 60 * 60 * 1000 >
      DateUtils.now().getTime()
  )
}

export const existsExperimentCookie = (context: CookieContext): boolean => {
  const cookie = useCookie(context)
  return (
    cookie.has(EnabledRunningUserExperimentsCookieName) &&
    cookie.has(DisabledRunningUserExperimentsCookieName) &&
    cookie.has(ExperimentsDateCookieName) &&
    cookie.has(Cookies.runningUserExperiments)
  )
}

export const generateVariants = (
  runningExperiments: Experiment[],
  context: CookieContext,
  changeVariant: string | string[]
): {
  runningUserExperiments: string
  runningExperiments: Experiment[]
  cookie: Cookie
} => {
  const cookie = useCookie(context)
  const enabledRunningUserExperiments =
    cookie.get<string>(EnabledRunningUserExperimentsCookieName) ?? ''
  const disabledRunningUserExperiments =
    cookie.get<string>(DisabledRunningUserExperimentsCookieName) ?? ''
  const enabledUserExperiments = parseRunningUserExperiments(
    enabledRunningUserExperiments
  )
  const disabledUserExperiments = parseRunningUserExperiments(
    disabledRunningUserExperiments
  )

  if (runningExperiments === null) {
    return {
      runningUserExperiments: cookie.get<string>(
        Cookies.runningUserExperiments
      ),
      runningExperiments: null,
      cookie
    }
  }

  const enabledVariants: string[] = []
  const disabledVariants: string[] = []

  const defaultVariant: string[] = changeVariant?.toString().split('.')
  const experimentId: string = defaultVariant?.[0]
  const experimentVariantIndex: string = defaultVariant?.[1]

  runningExperiments.forEach((experiment) => {
    const existsVariant = (variantIndex) =>
      experiment.variants.find(
        (item) => item.variantIndex.toString() === variantIndex
      )
    const active = isRunningExperimentActive(experiment)
    let variant: string = null
    // variant from url
    if (
      experiment.id === experimentId &&
      existsVariant(experimentVariantIndex)
    ) {
      variant = experimentVariantIndex
    }
    // variant from cookie
    if (!variant) {
      const cookieVariant = (
        active ? enabledUserExperiments : disabledUserExperiments
      ).find((item) => item.id === experiment.id)?.variant
      if (existsVariant(cookieVariant)) {
        variant = cookieVariant
      }
    }
    // generate new if undefined
    variant = variant ?? generateVariant(experiment)
    const variantString = `${experiment.id}.${variant}`
    if (active) {
      enabledVariants.push(variantString)
    } else {
      disabledVariants.push(variantString)
    }
  })
  const enabledValue = enabledVariants.join('!')
  const disabledValue = disabledVariants.join('!')
  const value = [...enabledVariants, ...disabledVariants].join('!')

  cookie.set(
    EnabledRunningUserExperimentsCookieName,
    enabledValue,
    cookieOptions
  )
  cookie.set(
    DisabledRunningUserExperimentsCookieName,
    disabledValue,
    cookieOptions
  )
  cookie.set(
    ExperimentsDateCookieName,
    DateUtils.now().getTime(),
    cookieOptions
  )
  cookie.set(Cookies.runningUserExperiments, value, cookieOptions)
  return { runningUserExperiments: value, runningExperiments, cookie }
}

export const getExperiments = async (sdk: Sdk): Promise<Experiment[]> => {
  const { success, response } = await requestThrow401({
    method: sdk.getExperiments,
    requestHeaders: {
      'x-cachable': '1'
    }
  })
  return success ? response?.experiments ?? [] : []
}

export const isRunningExperimentActive = (experiment: Experiment): boolean => {
  if (!experiment) {
    return false
  }
  const from = experiment.from ? DateUtils.fromISO(experiment.from) : null
  const to = experiment.to ? DateUtils.fromISO(experiment.to) : null
  const now = DateUtils.now()
  return (
    experiment.status === ExperimentStatus.Active &&
    (from === null || from <= now) &&
    (to === null || to >= now)
  )
}

const getVariantBase = (
  experiment: ExperimentType,
  runningExperiments: Experiment[],
  runningUserExperiments: string,
  defaultVariant = '0'
): string => {
  const experimentObject = runningExperiments.find(
    (item) => item.name === experiment
  )
  if (!experimentObject) {
    return defaultVariant
  }
  const variant = parseRunningUserExperiments(runningUserExperiments).find(
    (item) => item.id === experimentObject.id
  )?.variant
  if (
    !experimentObject.variants.find(
      (item) => item.variantIndex.toString() === variant
    )
  ) {
    return defaultVariant
  }
  return variant ?? defaultVariant
}

// export const pushExperimentEventToMHub = (
//   experimentType: ExperimentType,
//   getRunningExperiments: () => Experiment[],
//   getVariant: (experiment: ExperimentType, defaultVariant?: string) => string,
//   pushExperimentEvent: (experiment: ExperimentMhubItem) => void
// ): void => {
//   const runningExperiments = getRunningExperiments()

//   const enabledExperiment = runningExperiments.find(
//     (experiment) => experiment.name === experimentType
//   )
//   const variantId = +getVariant(enabledExperiment?.name as ExperimentType)

//   const createExperimentEventSessionStorage = (variantId: number) => {
//     const variant = enabledExperiment?.variants.find(
//       (variant) => variant.variantIndex === variantId
//     )
//     return {
//       name: enabledExperiment?.name,
//       id: enabledExperiment?.id,
//       variant: variant?.name,
//       variantId: variantId
//     }
//   }

//   const experimentEventSessionStorage =
//     createExperimentEventSessionStorage(variantId)

//   pushExperimentEvent(experimentEventSessionStorage)
// }
