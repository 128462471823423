import { FC, memo, useContext } from 'react'
import styled, { useTheme } from 'styled-components'
import { Box, Flex } from '@/components/atoms/Grid'
import { Capitalized, Small, Tiny2 } from '@/components/atoms/typography'
import useTranslation from 'next-translate/useTranslation'
import Link from '@/components/atoms/link'
import { Routes } from '@/core/routes'
import { transparentize } from 'polished'
import CartDropdown from '@/components/organisms/cartDropdown'
import { CurrencyContext } from '@/providers/currencyProvider'
import { MessageType, useMessages } from '@/providers/messageProvider'
import { useOrder } from '@/providers/order/old/orderProvider'
import { makePriceString } from '@/core/toStringHelper'
import { CartIconDefault } from '../atoms/icons/CartIconDefault'
import { useScrollCondition } from '../../hooks/useScroll'

type CartProps = {
  isShowingOnDefaultToolbar: boolean
} & Rest

const Cart: FC<CartProps> = memo(
  ({ isShowingOnDefaultToolbar, ...rest }) => {
    const defaultCurrency = useContext(CurrencyContext)
    const { t } = useTranslation('common')
    const theme = useTheme()
    const { order, count } = useOrder()
    const messages = useMessages()
    const showDropdown = messages?.find(
      (item) => item.type === MessageType.AddedToCart
    )

    const Offset = 139
    const isSticky = useScrollCondition((scroll) => scroll > Offset)

    return (
      <StyledBox>
        <Link href={Routes.cart}>
          <StyledFlex data-cy="cart" {...rest}>
            <StyledCounterBox>
              <StyledCounterText data-cy="headerCartCounter">
                {count}
              </StyledCounterText>
            </StyledCounterBox>
            <CartIconDefault color={theme.colors.onBackground} ml={14} />

            <StyledCartTitle>{t('Toolbar.Buttons.cart')}</StyledCartTitle>
            <Flex alignItems="center">
              <StyledCartPrice data-cy="headerCartPricee">
                {order?.cartPrices &&
                  order?.cartPrices?.currency &&
                  makePriceString({
                    allowZero: true,
                    price: order?.cartPrices?.priceWithVat,
                    currency: order?.cartPrices?.currency ?? defaultCurrency
                  })}
              </StyledCartPrice>
            </Flex>
          </StyledFlex>
        </Link>
        <StyledCartDropdown
          showToast={showDropdown}
          count={count}
          order={order}
          isSticky={isSticky}
          isShowingOnDefaultToolbar={isShowingOnDefaultToolbar}
        />
      </StyledBox>
    )
  },
  () => true
)

export default Cart

const StyledFlex = styled(Flex)`
  ${(props) => props.theme.headerCart && props.theme.headerCart};
  flex-direction: row;
  width: fit-content;
  height: fit-content;
  align-self: center;
  border-radius: ${(props) => props.theme.borderRadius}px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      transparentize(0.97, props.theme.colors.onBackground)};
  }
`

const StyledCounterBox = styled(Flex)`
  width: 38px;
  justify-content: center;
  background: ${(props) => props.theme.colors.headerButton};
  border-top-left-radius: ${(props) => props.theme.borderRadius}px;
  border-bottom-left-radius: ${(props) => props.theme.borderRadius}px;
  margin-left: -1px;
`

const StyledCounterText = styled(Small)`
  margin: 0;
  color: white;
  line-height: normal;
  text-align: center;
  align-self: center;
`

const StyledCartTitle = styled(Tiny2)`
  opacity: 0.6;
  margin-left: 8px;
`
const StyledCartPrice = styled(Capitalized)`
  margin-left: ${({ dualPrice }) => (dualPrice ? 0 : 14)}px;
  margin-right: ${({ dualPrice }) => (dualPrice ? 14 : 5)}px;
  color: ${(props) => props.theme.colors.accent};
  ${({ dualPrice }) => dualPrice && 'font-size: 10px;'}
`

const animationDuration = '0.2s'
const cartDropdownMaxHeight = '600px'

const getMaxHeight = (props) =>
  (props.showToast && !props.isShowingOnDefaultToolbar) ||
  (!props.isSticky && props.isShowingOnDefaultToolbar && props.showToast)
    ? cartDropdownMaxHeight
    : 0

const StyledCartDropdown = styled(CartDropdown)`
  overflow: hidden;
  max-height: ${getMaxHeight};
  transition: ${animationDuration} ease;
  -webkit-transition: ${animationDuration} ease;
  -moz-transition: ${animationDuration} ease-out;
  -ms-transition: ${animationDuration} ease-out;
  -o-transition: ${animationDuration} ease-out;
  transition: ${animationDuration} ease-out;
`

const StyledBox = styled(Box)`
  position: relative;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: ${(props) => props.theme.borderRadius}px;
  &:hover {
    ${StyledCartDropdown} {
      max-height: ${cartDropdownMaxHeight};
    }
  }
`
