import { FC } from 'react'
import Badge from '@/components/atoms/badge'
import Link from '@/components/atoms/link'
import { Flex } from '@/components/atoms/Grid'
import styled from 'styled-components'

import { validFor } from '@/theme/validFor'
import { useAsset } from '@/providers/assetsProvider'

type MobileHeaderButton = {
  hrefLink: string
  notifyCount?: number
  IconElement: JSX.Element
} & Rest

const MobileHeaderButton: FC<MobileHeaderButton> = ({
  hrefLink,
  notifyCount,
  IconElement,
  ...rest
}) => {
  const { isNewSearch } = useAsset()

  const useBreadcrumbDetailMobileNew = isNewSearch

  const sizeBadge: number = notifyCount < 10 ? 16 : notifyCount < 100 ? 20 : 22

  return (
    <Link href={hrefLink}>
      <StyledFlex {...rest}>
        {IconElement}
        {notifyCount && notifyCount > 0 ? (
          <StyledBadge
            sizeBadge={sizeBadge}
            useBreadcrumbDetailMobileNew={useBreadcrumbDetailMobileNew}
            counter={notifyCount}
          />
        ) : null}
      </StyledFlex>
    </Link>
  )
}
export default MobileHeaderButton

const StyledFlex = styled(Flex)`
  flex-direction: column;
  margin: 10px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`

const StyledBadge = styled(Badge)`
  position: absolute;
  left: 60%;
  bottom: 50%;

  ${(props) =>
    validFor.mobile &&
    props.useBreadcrumbDetailMobileNew &&
    `
      font-size: 12px;
      min-width: ${props.sizeBadge}px;
      border: 1.5px solid white;
      height: 16px;
      padding-bottom: 1px;
      padding-right: 1px;
    `}
`
