import { MarketCode } from '@/network/graphql.g'

export interface ExtRoutes {
  facebook: string
  linkedIn: string
  twitter: string
  instagram: string
  pinterest: string
}

export const ExtRoutesBaseUrl = {
  facebook: 'https://www.facebook.com',
  linkedIn: 'https://www.linkedin.com/company',
  twitter: 'https://twitter.com',
  instagram: 'https://www.instagram.com',
  pinterest: 'https://sk.pinterest.com'
}

export const FactcoolFacebookMarkets = [
  MarketCode.Ba,
  MarketCode.Hr,
  MarketCode.Pl,
  MarketCode.Si,
  MarketCode.Bg,
  MarketCode.Ro,
  MarketCode.Sk,
  MarketCode.Rs
]

export const AliaticFacebookMarkets = [
  MarketCode.Cs,
  MarketCode.Sk,
  MarketCode.Hu,
  MarketCode.Si,
  MarketCode.Hr,
  MarketCode.Ro
]

export const FrogiesFacebookMarkets = [
  MarketCode.Cs,
  MarketCode.Sk,
  MarketCode.Bg,
  MarketCode.Ba,
  MarketCode.Hu,
  MarketCode.Ro,
  MarketCode.Hr,
  MarketCode.Rs,
  MarketCode.Gr,
  MarketCode.At,
  MarketCode.De
]

export const FrogiesInstagramMarkets = [
  MarketCode.Cs,
  MarketCode.Sk,
  MarketCode.Bg,
  MarketCode.Ba,
  MarketCode.Hu,
  MarketCode.Ro,
  MarketCode.Hr,
  MarketCode.Rs,
  MarketCode.Gr,
  MarketCode.At,
  MarketCode.De
]

export const getInstagramCodeFrogies = (marketCode: string): string => {
  const default_prefix = '.'

  switch (marketCode) {
    case MarketCode.Ba:
      return default_prefix + MarketCode.Ba
    case MarketCode.At:
      return default_prefix + MarketCode.At
    case MarketCode.Bg:
      return default_prefix + MarketCode.Bg
    case MarketCode.Ee:
      return default_prefix + MarketCode.Ee
    case MarketCode.Es:
      return default_prefix + MarketCode.Es
    case MarketCode.Hr:
      return default_prefix + MarketCode.Hr
    case MarketCode.Hu:
      return default_prefix + MarketCode.Hu
    case MarketCode.It:
      return default_prefix + MarketCode.It
    case MarketCode.Lt:
      return default_prefix + MarketCode.Lt
    case MarketCode.Lv:
      return default_prefix + MarketCode.Lv
    case MarketCode.Pl:
      return default_prefix + MarketCode.Pl
    case MarketCode.Rs:
      return default_prefix + MarketCode.Rs
    case MarketCode.Si:
      return default_prefix + MarketCode.Si
    case MarketCode.Sk:
      return default_prefix + MarketCode.Sk
    case MarketCode.Ua:
      return default_prefix + MarketCode.Ua
    case MarketCode.Gr:
      return default_prefix + MarketCode.Gr
    case MarketCode.Cs:
      return default_prefix + 'cz'
    case MarketCode.Ro:
      return default_prefix + 'romania'
    case MarketCode.De:
      return '_' + MarketCode.De
    default:
      return default_prefix + 'com'
  }
}

export const getInstagramCodeFactcool = (marketCode: string): string => {
  const default_prefix = '_'
  switch (marketCode) {
    case MarketCode.Ba:
      return default_prefix + 'bosna'
    case MarketCode.At:
      return default_prefix + MarketCode.At
    case MarketCode.Bg:
      return default_prefix + MarketCode.Bg
    case MarketCode.Cs:
      return default_prefix + 'cz'
    case MarketCode.De:
      return default_prefix + MarketCode.De
    case MarketCode.Ee:
      return default_prefix + MarketCode.Ee
    case MarketCode.Es:
      return default_prefix + MarketCode.Es
    case MarketCode.Hr:
      return default_prefix + MarketCode.Hr
    case MarketCode.Hu:
      return default_prefix + MarketCode.Hu
    case MarketCode.It:
      return default_prefix + MarketCode.It
    case MarketCode.Lt:
      return default_prefix + MarketCode.Lt
    case MarketCode.Lv:
      return default_prefix + MarketCode.Lv
    case MarketCode.Pl:
      return default_prefix + MarketCode.Pl
    case MarketCode.Ro:
      return default_prefix + MarketCode.Ro
    case MarketCode.Rs:
      return default_prefix + MarketCode.Rs
    case MarketCode.Si:
      return default_prefix + MarketCode.Si
    case MarketCode.Sk:
      return default_prefix + MarketCode.Sk
    case MarketCode.Ua:
      return '.' + MarketCode.Ua
    default:
      return default_prefix + 'com'
  }
}
