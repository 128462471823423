import { FC } from 'react'

const ArrowDownIconDefinition: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="arrow-down-icon-definition">
      <defs>
        <filter id="pzt9a5cc3a" colorInterpolationFilters="auto">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g transform="translate(-155 -175)">
          <path
            strokeWidth="1"
            d="M7.8 0.9L4.2 4.5 7.8 8.1"
            transform="translate(155 175) matrix(0 -1 -1 0 10.5 10.5)"
          />
        </g>
      </g>
    </symbol>
  </svg>
)

export default ArrowDownIconDefinition
