import { ITheme } from './interfaces/ITheme'
import IColors from './interfaces/IColors'

const FrogiesColors: IColors = {
  listItemSeparator: '#f2f2f2',
  menuBackground: '#ffffff',
  menuItemBackgroundSecondary: '#eeeeee',
  onBackgroundSecondary: '#151414',
  sectionSeparator: '#dedede',
  accent: '#f04e23',
  neutralBorder: '#c1c1c1',
  background: '#ffffff',
  onBackground: '#000000',
  inputPlaceholder: '#8d8d8d',
  tableBackground: '#dcdcdc',
  highlight: '#00afff',
  placeholder: '#8d8d8d',
  accentSecondary: '#35ab6d',
  error: '#e74c3c',
  errorSecondary: '#f01e4b',
  success: '#07bc0c',
  info: '#3498db',
  accentTertiary: '#238C5A',
  infoSecondary: '#666666',
  facebook: '#4267b2',
  twitter: '#6abaeb',
  warning: '#f1c40f',
  successSecondary: '#04AF8A',
  successTertiary: '#238C55',
  giftCardBackground: '#ffc800',
  specialLandingPageBackground: '#feb202',
  discountLabelBackgroundColor: '#f04e23',
  discountLabelColor: '#ffffff',
  freeShippingLabelBackgroundColor: '#05ae8a',
  freeShippingLabelColor: '#ffffff',
  toolbar: '#ffb52f',
  toolbarSeparator: '#ffffff',
  footerBackground: '#00af3f',
  mobileBackground: '#f2f2f2',
  bannerBackground: '#eae4fb',
  headerButton: '#00af3f',
  blackFridayBackgroundButton: '#ffc700',
  blackFridayMainBackground: '#121212',
  blackFridayHeaderBackground: '#F7CA3F',
  luigiMainColor: '#120872',
  luigiHoverColor: '#fffccd',
  christmasBackgroundButton: '#437144',
  christmasMainBackground: '#f3f1ec',
  valentineBackgroundButton: '#d64d70',
  valentineMainBackground: '#f3eced',
  valentineArrow: '#ffc800',
  summerBackgroundButton: '#ffffff',
  summerMainBackground: '#eccda7',
  checkoutMainColor: '#808080',
  blogNeutralBackground: '#f4f4f4',
  sizeTableDescription: '#757575',
  sizeTableNeutral1: '#f0f0f0',
  sizeTableNeutral2: '#f5f5f5',
  sizeTableNeutral3: '#e3e3e3',
  ratingProgressBarNeutral: '#d9d9d9',
  ratingStar: '#FFC800',
  ratingReviewDate: '#989898',
  ratingReviewInfoNeutral: '#707070',
  ratingReviewVerified: '#35AB6D',
  facebookHover: '#045FD4',
  googleButtonBorder: '#747775',
  loginInactiveBackground: '#5A5A5A',
  buttonPopup: '#FFC800',
  cartCoupon: '#47895E',
  cartCouponBackground: '#e0f2e9',
  greyBackground: '#f8f8f8',
  searchButton: '#8f8f8f',
  searchInputColor: '#000000',
  towerColorPrimary: '#f8c132',
  towerColorSecondary: '#242424',
  towerColorSuccess: '#82b045',
  towerColorError: '#bb1e3c',
  towerColorBlack: '#000000',
  towerColorBlack47: '#787878',
  towerColorBlack82: '#d1d1d1',
  towerColorBlack95: '#f4f4f4',
  towerColorWhite: '#ffffff',
  towerColorGreen: '#6aa939',
  towerColorGreenDark: '#52832c',
  towerColorGreenLight: '#e9f2e1',
  towerColorGreyLight: '#939393',
  towerColorGreenUltraLight: '#e2f1d6'
}

export const FrogiesTheme: ITheme = {
  colors: FrogiesColors,
  hasToolbarBackground: true,
  borderRadius: 8,
  buttonBorderRadius: 8,
  headerCart: `
    border: none;
    &.sticky {
      border: 1px solid #000;
    }
  `,
  headerSearchInput: `
    border: 1px solid #fff;
    &:focus {
      border: 1px solid #fff;
    }
  `,
  headerSearchButton: ``,
  footerTextTransparency: 0.8,
  separatorWidth: 'width: 98%; width: calc(100% - 16px);',
  logo: {
    size: {
      desktop: {
        width: 124,
        height: 61
      },
      tablet: {
        width: 81,
        height: 40
      },
      mobile: {
        width: 61,
        height: 30
      }
    }
  },
  logoWidth: 65,
  logoHeigth: 30
}
