import { CountryId, StoreId } from '@/network/graphql.g'

interface TrackerData {
  baseId: string
  storeId: Record<string, Record<string, string>>
}

const trackerData: TrackerData = {
  baseId: '124029-',
  storeId: {
    [StoreId.Factcool]: {
      [CountryId.Gr]: '140796',
      [CountryId.Ee]: '140759',
      [CountryId.Lt]: '140734',
      [CountryId.Lv]: '140679',
      [CountryId.Pl]: '140617',
      [CountryId.Si]: '140532',
      [CountryId.Rs]: '140472',
      [CountryId.Ro]: '140465',
      [CountryId.It]: '140453',
      [CountryId.Hu]: '140434',
      [CountryId.Hr]: '140343',
      [CountryId.Bg]: '140280',
      [CountryId.Ba]: '140265',
      [CountryId.Cz]: '140158',
      [CountryId.Sk]: '140103',
      [CountryId.At]: '143670',
      [CountryId.De]: '143670',
      [CountryId.Es]: '188802',
      [CountryId.Ua]: '188809',
      [CountryId.Nl]: '143670'
    },
    [StoreId.Aliatic]: {
      [CountryId.Gr]: '140796',
      [CountryId.Ee]: '140759',
      [CountryId.Lt]: '140734',
      [CountryId.Lv]: '140679',
      [CountryId.Pl]: '140617',
      [CountryId.Si]: '140532',
      [CountryId.Rs]: '140472',
      [CountryId.Ro]: '140465',
      [CountryId.It]: '140453',
      [CountryId.Hu]: '140434',
      [CountryId.Hr]: '140343',
      [CountryId.Bg]: '140280',
      [CountryId.Ba]: '140265',
      [CountryId.Cz]: '140158',
      [CountryId.Sk]: '140103',
      [CountryId.At]: '143670',
      [CountryId.De]: '143670',
      [CountryId.Es]: '188802',
      [CountryId.Ua]: '188809'
    },
    [StoreId.Bezvasport]: {
      [CountryId.Cz]: '296189',
      [CountryId.Sk]: '296125'
    }
  }
}

export default trackerData
