type ImageSizeValue = {
  width: number
  height: number
}

export enum ImageSizeKey {
  m = 'm',
  xs = 'xs',
  s = 's',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl',
  xxs = 'xxs'
}

interface ConfigOptions {
  countryGetParameter: string
  perInitialPage: number
  perPage: number
  perPageLuigi: number
  imageSizes: Record<ImageSizeKey, ImageSizeValue>
  bannerSizes: Record<'m' | 'xs' | 's' | 'l' | 'xl' | 'xxl', ImageSizeValue>
  insurancePackageId: string
  maxPageWidth: number
  wideScreenPageWidth: number
  imagePolicyQueryParameter: string
  deprecatedSlugs: string[]
  cdnImageBaseUrl: string
}

export const Config: ConfigOptions = {
  insurancePackageId: 'something',
  countryGetParameter: '_country',
  maxPageWidth: 1200,
  wideScreenPageWidth: 1600,
  perInitialPage: 8,
  perPage: 24, // divisible by 2,3,4
  perPageLuigi: 24,
  imagePolicyQueryParameter: 'impolicy',
  imageSizes: {
    xxs: { width: 160, height: 50 },
    m: { width: 358, height: 219 },
    xs: { width: 203, height: 140 },
    s: { width: 598, height: 180 },
    l: { width: 600, height: 280 },
    xl: { width: 410, height: 615 },
    xxl: { width: 1400, height: 1400 }
  },
  bannerSizes: {
    m: { width: 358, height: 219 },
    xs: { width: 203, height: 140 },
    s: { width: 598, height: 180 },
    l: { width: 600, height: 280 },
    xl: { width: 800, height: 400 },
    xxl: { width: 1400, height: 1400 }
  },
  deprecatedSlugs: ['index.php'],
  cdnImageBaseUrl: 'https://i.factcool.com'
}
